import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MouseFollower from "mouse-follower";
import Lenis from '@studio-freight/lenis'

gsap.registerPlugin(ScrollTrigger);
MouseFollower.registerGSAP(gsap);

let tl = gsap.timeline({
    scrollTrigger: {
        trigger: "#main",
        //markers: true,
        start: "50% 50%",
        end: "150% 50%",
        scrub: 2,
        pin: true
    }
});

tl
    .to("#center", {
        height: "100vh",
    }, 'a')
    .to("#top", {
        top: "-50%",
    }, 'a')
    .to("#bottom", {
        bottom: "-50%",
    }, 'a')
    .to("#top-h1", {
        top: "60%"
    }, 'a')
    .to("#bottom-h1", {
        bottom: "-30%"
    }, 'a')
    .to("#center-h1", {
        top: "-30%"
    }, 'a')
    .to(".content", {
        delay: -0.2,
        marginTop: "0%"
    });

const lenis = new Lenis({
    lerp: 0.1
});

function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

// Canvas
const cursor = new MouseFollower({
    container: document.body,
    skewing: 2
});
cursor.addState('-exclusion');
cursor.setText('Scroll Down');

const topH1 = document.querySelector('#top-h1');
topH1.addEventListener('mouseenter', () => {
    cursor.removeText();
});
topH1.addEventListener('mouseleave', () => {
    cursor.setText('Scroll Down');
});

const bottomH1 = document.querySelector('#bottom-h1');
bottomH1.addEventListener('mouseenter', () => {
    cursor.removeText();
});
bottomH1.addEventListener('mouseleave', () => {
    cursor.setText('Scroll Down');
});

const center = document.querySelector('#center');
center.addEventListener('mouseenter', () => {
    cursor.removeText();
});
center.addEventListener('mouseleave', () => {
    cursor.setText('Scroll Down');
});

const el = document.querySelector('.content');
el.addEventListener('mouseenter', () => {
    cursor.addState('-zoom');
});

el.addEventListener('mouseleave', () => {
    cursor.removeState('-zoom');
});